<script lang="ts" setup>
import { MenuItem } from '~/api/drupal/useMenu'
import { MainMenuItem } from './useMainMenu'

const props = defineProps<{
  item: MainMenuItem
}>()
const items =
  props.item.items
    ?.map((i) => i.items)
    .flat()
    .reduce((acc: MenuItem[], item) => {
      if (!item) return acc
      if (item?.title === '‎') {
        acc[acc.length - 1].items?.push(...(item?.items || []))
        return acc
      }
      return [...acc, item]
    }, [])
    .map((item) => {
      return {
        title: item?.title,
        items: [...(item?.items || [])].map((item) => {
          return {
            id: item.id,
            link: {
              text: item.title,
              url: item.url,
              target: item.target,
            },
          }
        }),
      }
    }) || []
</script>
<template>
  <div class="sub-menu">
    <h3 class="title text-xlarge">{{ item.title }}</h3>
    <Accordion>
      <AccordionItem v-for="(i, index) in items" :key="index" :index="index" :title="i.title || ''">
        <template #icon="{ isActive }">
          <Icon :name="isActive ? 'mini-arrow-up' : 'mini-arrow-down'" />
        </template>
        <LinkList :items="i.items" />
      </AccordionItem>
    </Accordion>
  </div>
</template>
<style lang="scss" scoped>
.sub-menu {
  @include breakpoint(tl) {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: auto auto 1fr;
    gap: var(--gap);
    align-items: start;
  }
}
.title {
  grid-column: 1;
  margin-top: 32px;
  margin-bottom: 48px;
}

.accordion {
  grid-column: 1;
}
.accordion-item {
  border: none;
  margin-bottom: 18px;

  :deep(.accordion-head) {
    padding: 0;
    display: flex;
    // flex-direction: row-reverse;
    justify-content: space-between;

    .button {
      --icon-size: 16px;
      padding: 0;
      border: none;
      .background {
        display: none;
      }
    }
  }

  :deep(.accordion-content) {
    width: calc(100% + 24px);
    translate: -12px;
    // padding-block: 12px;
    .inner-content {
      padding: 12px 0 0;
    }
  }
}

.link-list {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 12px 24px 30px;
  background-color: var(--c-background-medium);

  &::before {
    --size: 10px;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 50%;
    translate: -50%;
    border-style: solid;
    border-width: 0 var(--size) var(--size) var(--size);
    border-color: transparent transparent var(--c-background-medium) transparent;
  }

  :deep(li) {
    width: 100%;
  }
}
</style>
